import { Flex, Text } from "@chakra-ui/react";
import React, { ReactNode } from "react";

export const TimelineCard = ({
  year,
  content,
}: {
  year: string;
  content: ReactNode;
}) => {
  return (
    <Flex w="70%" flexDirection="column" my="3rem" justifyContent={"center"}>
      <Flex
        borderRadius={"50%"}
        w="100px"
        h="100px"
        alignSelf={"center"}
        backgroundColor="#32aab5"
        justifyContent={"center"}
        alignItems="center"
        my="1rem"
      >
        <Text
          textAlign={"center"}
          fontSize="1.15rem"
          color="#ffff"
          fontFamily="Open Sans"
        >
          {year}
        </Text>
      </Flex>
      {content}
    </Flex>
  );
};
